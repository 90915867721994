export const aboutQualityText = `<p>Для производства наших продуктов мы используем только качественные материалы, чтобы они радовали вас долгие годы. Фотографии будут напечатаны на гладкой белой бумаге глянцевыми красками: такой выбор позволяет максимально точно передать насыщенность и все оттенки исходных снимков.</p><p>Печатаем на профессиональном типографском оборудовании цифровым офсетом. Это позволяет добиться журнального качества. Если вы ни разу не печатали фотографии цифровым офсетом, советуем ознакомиться <a href='/blog/nash-metod-pechati' target='_blank'>с примерами и описанием печати.</a></p><p>Процессы производства полностью автоматизированы и сохраняют конфиденциальность ваших фотографий и данных.</p>`;
export const aboutPreparationText = `Мы <b>настоятельно</b> рекомендуем минимальную обработку перед печатью: поднять насыщенность, контраст и яркость. Особое внимание стоит уделить темным снимкам, сделанным против света или при недостаточной освещенности. <a href='/blog' target='_blank'>Подробнее о подготовке фотографий читайте в советах.</a>`;

export const allowedProductCategories = [
  'photobooks',
  'wedding',
  'detskaya',
  'sale',
  'other',
  'calendars',
  'traditions',
];

export const allowedPageCounts = [
  '24',
  '30',
  '40',
  '60',
  '80',
  '90',
  '100',
  '112',
  '120',
  '148',
  '160',
];

export const allowedCoverTypes = ['hard', 'cloth', 'soft'];

export const allowedFormatTypes = ['square', 'vertical', 'horizontal'];
